const dev = {
  endpoint: "http://localhost:8888",
  enableLogRocket: false,
  enableHotJar: false
};

const qa = {
  endpoint: "https://api-qa.treatmyocd.com",
  enableLogRocket: true,
  enableHotJar: true
};

const qa_local = {
  endpoint: "https://api-qa.treatmyocd.com",
  enableLogRocket: false,
  enableHotJar: false
}

const prod = {
  endpoint: "https://api.treatmyocd.com",
  enableLogRocket: true,
  enableHotJar: true
};

const prod_local = {
  endpoint: "https://api.treatmyocd.com",
  enableLogRocket: false,
  enableHotJar: false
}

const config = (() => {
  // Running Locally
  if (process.env.REACT_APP_DEV === '1') {
    if (process.env.REACT_APP_ENV === 'prod') {
      return prod_local;
    } else if (process.env.REACT_APP_ENV === 'qa') {
      return qa_local;
    } else {
      return dev;
    }  
  // Running on build servers
  } else {
    if (process.env.REACT_APP_ENV === 'prod') {
      return prod;
    } else if (process.env.REACT_APP_ENV === 'qa') {
      return qa;
    } else {
      return qa
    }
  }
})();

export { config }

