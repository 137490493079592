import * as React from "react"

export function SvgExitComponent(props) {
  return (
    <svg viewBox="0 0 35 35" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={17.5} cy={17.5} r={17.5} fill="#EFF3FA" />
        <path
          d="M17.5 16.259l5.013-5.004a.87.87 0 011.233.002.875.875 0 01-.003 1.235L18.726 17.5l5.017 5.008c.341.34.343.894.003 1.235a.87.87 0 01-1.233.003L17.5 18.74l-5.013 5.005a.87.87 0 01-1.233-.003.875.875 0 01.003-1.235l5.017-5.008-5.017-5.008a.875.875 0 01-.003-1.235.87.87 0 011.233-.002l5.013 5.004z"
          fill="#000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export function SvgBackComponent(props) {
  return (
    <svg viewBox="0 0 35 35" {...props}>
      <title>{"close-button"}</title>
      <g fillRule="nonzero" fill="none">
        <circle fill="#EFF3FA" cx={17.5} cy={17.5} r={17.5} />
        <path
          d="M14.156 16.259l5.013-5.004a.87.87 0 011.233.002.875.875 0 01-.003 1.235L15.382 17.5l5.017 5.008c.341.34.342.893.003 1.235a.87.87 0 01-1.233.002l-5.013-5.004-1.09-1.241c-.228.227.136-.187 1.09-1.241z"
          fill="#000000"
        />
      </g>
    </svg>
  )
}

export function SVGBackArrow(props) {
  return (
    <svg viewBox="0 0 12 18" fill="none" {...props}>
      <path
        d="M10.107 2L3 9.435l6.45 6.747"
        stroke="#212121"
        strokeWidth={3}
        strokeLinecap="round"
      />
    </svg>
  )
}


export function SVGProgressBar1(props) {
  return (
    <svg viewBox="0 0 261 29" fill="none" {...props}>
      <rect width={29} height={29} rx={14.5} fill="#F5A623" />
      <path
        d="M12.102 10.722V7.86h4.986V21h-3.204V10.722h-1.782z"
        fill="#212121"
      />
      <rect
        x={77.5}
        y={0.5}
        width={28}
        height={28}
        rx={14}
        fill="#848484"
        stroke="#848484"
      />
      <path
        d="M87.06 18.534a66.663 66.663 0 003.33-2.844 11.322 11.322 0 001.728-1.962c.468-.684.702-1.35.702-1.998 0-.492-.114-.876-.342-1.152-.228-.276-.57-.414-1.026-.414-.456 0-.816.174-1.08.522-.252.336-.378.816-.378 1.44h-2.97c.024-1.02.24-1.872.648-2.556.42-.684.966-1.188 1.638-1.512.684-.324 1.44-.486 2.268-.486 1.428 0 2.502.366 3.222 1.098.732.732 1.098 1.686 1.098 2.862 0 1.284-.438 2.478-1.314 3.582-.876 1.092-1.992 2.16-3.348 3.204h4.86v2.502H87.06v-2.286z"
        fill="#fff"
      />
      <rect
        x={156.5}
        y={0.5}
        width={27}
        height={28}
        rx={13.5}
        fill="#848484"
        stroke="#848484"
      />
      <path
        d="M164.969 11.568c.048-1.284.468-2.274 1.26-2.97.792-.696 1.866-1.044 3.222-1.044.9 0 1.668.156 2.304.468.648.312 1.134.738 1.458 1.278a3.37 3.37 0 01.504 1.818c0 .792-.198 1.44-.594 1.944-.396.492-.858.828-1.386 1.008v.072c.684.228 1.224.606 1.62 1.134.396.528.594 1.206.594 2.034 0 .744-.174 1.404-.522 1.98-.336.564-.834 1.008-1.494 1.332-.648.324-1.422.486-2.322.486-1.44 0-2.592-.354-3.456-1.062-.852-.708-1.302-1.776-1.35-3.204h2.988c.012.528.162.948.45 1.26.288.3.708.45 1.26.45.468 0 .828-.132 1.08-.396.264-.276.396-.636.396-1.08 0-.576-.186-.99-.558-1.242-.36-.264-.942-.396-1.746-.396h-.576v-2.502h.576c.612 0 1.104-.102 1.476-.306.384-.216.576-.594.576-1.134 0-.432-.12-.768-.36-1.008s-.57-.36-.99-.36c-.456 0-.798.138-1.026.414-.216.276-.342.618-.378 1.026h-3.006zM29 15h48-48z"
        fill="#fff"
      />
      <path d="M29 15h48" stroke="#848484" strokeWidth={4} />
      <path d="M106 15h50-50z" fill="#fff" />
      <path d="M106 15h50" stroke="#848484" strokeWidth={4} />
      <rect
        x={233.5}
        y={0.5}
        width={27}
        height={28}
        rx={13.5}
        fill="#848484"
        stroke="#848484"
      />
      <path
        d="M241.291 18.66v-2.484l5.688-8.136h3.438v7.956h1.476v2.664h-1.476V21h-3.078v-2.34h-6.048zm6.264-7.236l-3.024 4.572h3.024v-4.572zM183 15h50z"
        fill="#fff"
      />
      <path d="M183 15h50" stroke="#848484" strokeWidth={4} />
    </svg>
  )
}

export function SVGProgressBar2(props) {
  return (
    <svg viewBox="0 0 261 29" fill="none" {...props}>
      <rect width={29} height={29} rx={14.5} fill="#F5A623" />
      <path
        d="M12.102 10.722V7.86h4.986V21h-3.204V10.722h-1.782z"
        fill="#212121"
      />
      <rect
        x={77.5}
        y={0.5}
        width={28}
        height={28}
        rx={14}
        fill="#F5A623"
        stroke="#F5A623"
      />
      <path
        d="M87.06 18.534a66.663 66.663 0 003.33-2.844 11.322 11.322 0 001.728-1.962c.468-.684.702-1.35.702-1.998 0-.492-.114-.876-.342-1.152-.228-.276-.57-.414-1.026-.414-.456 0-.816.174-1.08.522-.252.336-.378.816-.378 1.44h-2.97c.024-1.02.24-1.872.648-2.556.42-.684.966-1.188 1.638-1.512.684-.324 1.44-.486 2.268-.486 1.428 0 2.502.366 3.222 1.098.732.732 1.098 1.686 1.098 2.862 0 1.284-.438 2.478-1.314 3.582-.876 1.092-1.992 2.16-3.348 3.204h4.86v2.502H87.06v-2.286z"
        fill="#000"
      />
      <rect
        x={156.5}
        y={0.5}
        width={27}
        height={28}
        rx={13.5}
        fill="#848484"
        stroke="#848484"
      />
      <path
        d="M164.969 11.568c.048-1.284.468-2.274 1.26-2.97.792-.696 1.866-1.044 3.222-1.044.9 0 1.668.156 2.304.468.648.312 1.134.738 1.458 1.278a3.37 3.37 0 01.504 1.818c0 .792-.198 1.44-.594 1.944-.396.492-.858.828-1.386 1.008v.072c.684.228 1.224.606 1.62 1.134.396.528.594 1.206.594 2.034 0 .744-.174 1.404-.522 1.98-.336.564-.834 1.008-1.494 1.332-.648.324-1.422.486-2.322.486-1.44 0-2.592-.354-3.456-1.062-.852-.708-1.302-1.776-1.35-3.204h2.988c.012.528.162.948.45 1.26.288.3.708.45 1.26.45.468 0 .828-.132 1.08-.396.264-.276.396-.636.396-1.08 0-.576-.186-.99-.558-1.242-.36-.264-.942-.396-1.746-.396h-.576v-2.502h.576c.612 0 1.104-.102 1.476-.306.384-.216.576-.594.576-1.134 0-.432-.12-.768-.36-1.008s-.57-.36-.99-.36c-.456 0-.798.138-1.026.414-.216.276-.342.618-.378 1.026h-3.006zM23 15h54-54z"
        fill="#fff"
      />
      <path d="M23 15h54" stroke="#F5A623" strokeWidth={4} />
      <path d="M106 15h50-50z" fill="#fff" />
      <path d="M106 15h50" stroke="#848484" strokeWidth={4} />
      <rect
        x={233.5}
        y={0.5}
        width={27}
        height={28}
        rx={13.5}
        fill="#848484"
        stroke="#848484"
      />
      <path
        d="M241.291 18.66v-2.484l5.688-8.136h3.438v7.956h1.476v2.664h-1.476V21h-3.078v-2.34h-6.048zm6.264-7.236l-3.024 4.572h3.024v-4.572zM183 15h50-50z"
        fill="#fff"
      />
      <path d="M183 15h50" stroke="#848484" strokeWidth={4} />
    </svg>
  )
}


export function SVGProgressBar3(props) {
  return (
    <svg viewBox="0 0 261 29" fill="none" {...props}>
      <rect width={29} height={29} rx={14.5} fill="#F5A623" />
      <path
        d="M12.102 10.722V7.86h4.986V21h-3.204V10.722h-1.782z"
        fill="#212121"
      />
      <rect
        x={77.5}
        y={0.5}
        width={28}
        height={28}
        rx={14}
        fill="#F5A623"
        stroke="#F5A623"
      />
      <path
        d="M87.06 18.534a66.663 66.663 0 003.33-2.844 11.322 11.322 0 001.728-1.962c.468-.684.702-1.35.702-1.998 0-.492-.114-.876-.342-1.152-.228-.276-.57-.414-1.026-.414-.456 0-.816.174-1.08.522-.252.336-.378.816-.378 1.44h-2.97c.024-1.02.24-1.872.648-2.556.42-.684.966-1.188 1.638-1.512.684-.324 1.44-.486 2.268-.486 1.428 0 2.502.366 3.222 1.098.732.732 1.098 1.686 1.098 2.862 0 1.284-.438 2.478-1.314 3.582-.876 1.092-1.992 2.16-3.348 3.204h4.86v2.502H87.06v-2.286z"
        fill="#000"
      />
      <rect
        x={156.5}
        y={0.5}
        width={27}
        height={28}
        rx={13.5}
        fill="#F5A623"
        stroke="#F5A623"
      />
      <path
        d="M164.969 11.568c.048-1.284.468-2.274 1.26-2.97.792-.696 1.866-1.044 3.222-1.044.9 0 1.668.156 2.304.468.648.312 1.134.738 1.458 1.278a3.37 3.37 0 01.504 1.818c0 .792-.198 1.44-.594 1.944-.396.492-.858.828-1.386 1.008v.072c.684.228 1.224.606 1.62 1.134.396.528.594 1.206.594 2.034 0 .744-.174 1.404-.522 1.98-.336.564-.834 1.008-1.494 1.332-.648.324-1.422.486-2.322.486-1.44 0-2.592-.354-3.456-1.062-.852-.708-1.302-1.776-1.35-3.204h2.988c.012.528.162.948.45 1.26.288.3.708.45 1.26.45.468 0 .828-.132 1.08-.396.264-.276.396-.636.396-1.08 0-.576-.186-.99-.558-1.242-.36-.264-.942-.396-1.746-.396h-.576v-2.502h.576c.612 0 1.104-.102 1.476-.306.384-.216.576-.594.576-1.134 0-.432-.12-.768-.36-1.008s-.57-.36-.99-.36c-.456 0-.798.138-1.026.414-.216.276-.342.618-.378 1.026h-3.006z"
        fill="#000"
      />
      <path d="M23 15h54-54z" fill="#fff" />
      <path d="M23 15h54" stroke="#F5A623" strokeWidth={4} />
      <path d="M106 15h50-50z" fill="#fff" />
      <path d="M106 15h50" stroke="#F5A623" strokeWidth={4} />
      <rect
        x={233.5}
        y={0.5}
        width={27}
        height={28}
        rx={13.5}
        fill="#848484"
        stroke="#848484"
      />
      <path
        d="M241.291 18.66v-2.484l5.688-8.136h3.438v7.956h1.476v2.664h-1.476V21h-3.078v-2.34h-6.048zm6.264-7.236l-3.024 4.572h3.024v-4.572zM183 15h50-50z"
        fill="#fff"
      />
      <path d="M183 15h50" stroke="#848484" strokeWidth={4} />
    </svg>
  )
}


export function SVGProgressBar4(props) {
  return (
    <svg viewBox="0 0 261 29" fill="none" {...props}>
      <rect width={29} height={29} rx={14.5} fill="#F5A623" />
      <path
        d="M12.102 10.722V7.86h4.986V21h-3.204V10.722h-1.782z"
        fill="#212121"
      />
      <rect
        x={77.5}
        y={0.5}
        width={28}
        height={28}
        rx={14}
        fill="#F5A623"
        stroke="#F5A623"
      />
      <path
        d="M87.06 18.534a66.663 66.663 0 003.33-2.844 11.322 11.322 0 001.728-1.962c.468-.684.702-1.35.702-1.998 0-.492-.114-.876-.342-1.152-.228-.276-.57-.414-1.026-.414-.456 0-.816.174-1.08.522-.252.336-.378.816-.378 1.44h-2.97c.024-1.02.24-1.872.648-2.556.42-.684.966-1.188 1.638-1.512.684-.324 1.44-.486 2.268-.486 1.428 0 2.502.366 3.222 1.098.732.732 1.098 1.686 1.098 2.862 0 1.284-.438 2.478-1.314 3.582-.876 1.092-1.992 2.16-3.348 3.204h4.86v2.502H87.06v-2.286z"
        fill="#000"
      />
      <rect
        x={156.5}
        y={0.5}
        width={27}
        height={28}
        rx={13.5}
        fill="#F5A623"
        stroke="#F5A623"
      />
      <path
        d="M164.969 11.568c.048-1.284.468-2.274 1.26-2.97.792-.696 1.866-1.044 3.222-1.044.9 0 1.668.156 2.304.468.648.312 1.134.738 1.458 1.278a3.37 3.37 0 01.504 1.818c0 .792-.198 1.44-.594 1.944-.396.492-.858.828-1.386 1.008v.072c.684.228 1.224.606 1.62 1.134.396.528.594 1.206.594 2.034 0 .744-.174 1.404-.522 1.98-.336.564-.834 1.008-1.494 1.332-.648.324-1.422.486-2.322.486-1.44 0-2.592-.354-3.456-1.062-.852-.708-1.302-1.776-1.35-3.204h2.988c.012.528.162.948.45 1.26.288.3.708.45 1.26.45.468 0 .828-.132 1.08-.396.264-.276.396-.636.396-1.08 0-.576-.186-.99-.558-1.242-.36-.264-.942-.396-1.746-.396h-.576v-2.502h.576c.612 0 1.104-.102 1.476-.306.384-.216.576-.594.576-1.134 0-.432-.12-.768-.36-1.008s-.57-.36-.99-.36c-.456 0-.798.138-1.026.414-.216.276-.342.618-.378 1.026h-3.006z"
        fill="#000"
      />
      <path d="M23 15h54-54z" fill="#fff" />
      <path d="M23 15h54" stroke="#F5A623" strokeWidth={4} />
      <path d="M106 15h50-50z" fill="#fff" />
      <path d="M106 15h50" stroke="#F5A623" strokeWidth={4} />
      <rect
        x={233.5}
        y={0.5}
        width={27}
        height={28}
        rx={13.5}
        fill="#F5A623"
        stroke="#F5A623"
      />
      <path
        d="M241.291 18.66v-2.484l5.688-8.136h3.438v7.956h1.476v2.664h-1.476V21h-3.078v-2.34h-6.048zm6.264-7.236l-3.024 4.572h3.024v-4.572z"
        fill="#000"
      />
      <path d="M183 15h50-50z" fill="#F5A623" />
      <path d="M183 15h50" stroke="#F5A623" strokeWidth={4} />
    </svg>
  )
}

